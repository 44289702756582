import React from "react";
import { PageProps } from "gatsby";

import Layout from "../components/layout";
import SEOTags from "../components/seo-tags";

export default function NotFoundPage(_props: PageProps) {
  return (
    <Layout>
      <SEOTags description="Sidan kunde inte hittas" title="Sidan kunde inte hittas" />
      <div className="container" style={{ height: "100%" }}>
        <div style={{ textAlign: "center", margin: "10rem 0" }}>
          <h1>Vi kan tyvärr inte hitta sidan du letar efter.</h1>
        </div>
      </div>
    </Layout>
  );
}
